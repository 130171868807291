import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

function CPara(props) {
  return(
    <p className="text-gray-800 py-2 pr-4">
      {props.children}
    </p>
  )
}

function CUListI(props){
  return(<li className="text-gray-800 pl-4">{props.children}</li>)
}

function COListI(props){
  return(
    <li className="text-gray-800 pl-4">{props.children}</li>
  )
}

function COList(props){
  return(
    <ol className="ml-8 pl-4 list-decimal -mt-2 pb-2">{props.children}</ol>
  )
}

function CUList(props) {
  return(
    <ul className="ml-8 pl-4 list-disc -mt-2 pb-2">{props.children}</ul>
  )
}

function CHeadingTwo(props) {
  return(
    <h2 className="my-4 text-2xl font-semibold leading-tight">{props.children}</h2>
  )
}

function CHeadingThree(props) {
  return(
    <h3 className="my-4 text-xl font-semibold leading-tight">{props.children}</h3>
  )
}

function CHeadingFour(props) {
  return(
    <h4 className="my-3 font-semibold leading-tight">{props.children}</h4>
  )
}

function CoCText(props) {
  return(
    <div className="ml-4 my-2 px-4">
      <CPara>
        <strong>The WCCA Code of Conduct</strong> provides guidance on how our members demonstrate
        their professionalism, and raise the level of confidence the public has in
        our profession.
      </CPara>
      <CPara>
        Aligning ourselves with our Code of Conduct is how we, as members of the
        WCCA, meet our mission of growing <em>professionalism</em> amongst content
        creators.
      </CPara>
      <CPara>
        All members of the WCCA agree to uphold the WCCA and conduct their work in
        a manner aligned with this Code of Conduct.
      </CPara>
      <CPara>
        At the foundation of our Code of Conduct is our set of guiding principles:
      </CPara>
      <COList>
        <COListI>Create great content</COListI>
        <COListI>Hold a global mindset</COListI>
        <COListI>Value communication</COListI>
        <COListI>Develop continuously</COListI>
      </COList>
      <CHeadingTwo>Create Great Content</CHeadingTwo>
      <CPara>We create great content.
      </CPara>
      <CPara>
        We consider the quality expectations of our audience. Our audience is our readers, watchers, customers,
        clients, and anyone else who consumes our content. We consider the expectations and requirements of our audience
        when we create content.
      </CPara>
      <CPara>
        We create content for people, for communities. We consider the communities of our audience
        when we create content. We avoid creating content for algorithms, computer hardware, aritificial intelligence
        or any other non-human consumer.
      </CPara>
      <CPara>
        We do not plagiarise the work of others.
      </CPara>
      <CHeadingTwo>
        Hold a global mindset
      </CHeadingTwo>
      <CPara>
        We understand the communities of our audience are not limited by geographic boundaries.
      </CPara>
      <CPara>
        We are open to the different requirements and expectations that different communities may have.
      </CPara>
      <CHeadingTwo>
        Value communication
      </CHeadingTwo>
      <CPara>
        Content creation is a method of communication. We are considerate of how that 
        communication may be received and perceived by our audience and their commuity.
      </CPara>
      <CPara>
        We communicate with our clients, our peers, our community, and our audience to understand
        their expectations and requirements.
      </CPara>
      <CHeadingTwo>
        Develop continuously
      </CHeadingTwo>
      <CPara>
        We develop ourselves continuously.
      </CPara>
      <CPara>
        We seek new knowledge, yet we maintain an understanding of the principles of what we do. 
      </CPara>
      <CPara>
        We achieve this by engaging in Continuous Professional Development activities.
      </CPara>
      <CHeadingThree>
        Continuous Professional Development
      </CHeadingThree>
      <CPara>
        Members are required to keep a log of their continuous professional development activities to
        demonstrate how they have been meeting the objectives of Continuous Professional Development.
      </CPara>
      <CPara>
        This log should document at least 30 hours of continuous professional development within 12
        months prior to the most current entry.
      </CPara>
      <CPara>
        Each entry of the CPD log should contain at a minimum:
      </CPara>
      <CUList>
        <CUListI>Date of the activity</CUListI>
        <CUListI>Nature of the activity (e.g. attend webinar, read book)</CUListI>
        <CUListI>How it furthered knowledge or skills</CUListI>
        <CUListI>How many hours spent</CUListI>
      </CUList>
      <CPara>
        Your CPD log can be audited under the direction of the membership committee as a requirement
        of your continued membership of the WCCA.
      </CPara>
      <CHeadingFour>
        What are Continous Professional Development Activities?
      </CHeadingFour>
      <CPara>
        Continuous Professional Development activities include anything you have undertaken that furthers
        your knowledge or skills as a content creator.
      </CPara>
      <CPara>
        Examples include:
      </CPara>
      <CUList>
        <CUListI>Study at a university or an online course</CUListI>
        <CUListI>Attending seminars, conferences, or webinars</CUListI>
        <CUListI>Watching videos or reading books or blogs relevant to furthering your skillset</CUListI>
      </CUList>
      <CPara>
        Anything that meets the objective of furthering your knowledge and skills
        as a content creator can be claimed as CPD.
      </CPara>
      <CHeadingTwo>Obligations of Membership</CHeadingTwo>
      <CPara>
        As a member, you agree to uphold these obligations with respect to the WCCA:
      </CPara>
      <CHeadingFour>Continuous Professional Development</CHeadingFour>
      <CPara>
        The Membership Committee may at any time request to audit the log of entries a
        member maintains as part of their obligation for Continuous Professional Development.
        While the Committee understands recording certificates or other types of verifiable evidence 
        may be impractical for many of the activities the WCCA recognises as professional
        development, a member is still obliged to maintain their log as best they can
        for the purposes of audit. Failure to undertake the minimum continuous professional
        development, or failure to produce a log of continuous professional development upon
        request, may be considered by the Committee to be a breach of the Code of Conduct.
      </CPara>
      <CHeadingFour>Public Statements and Use of Brand</CHeadingFour>
      <CPara>
        Members must not present themselves as representatives of the WCCA unless authorised by the Membership Committee.
      </CPara>
      <CHeadingFour>Breach of the Code of Conduct</CHeadingFour>
      <CPara>
        If a member, through their work, breaches the Code of Conduct, that member may
        be the subject of discipinary action as determined by the Membership Committee.
      </CPara>
      <CPara>
        If a member brings into disripute the WCCA, that member may be the subject of
        disciplinary action as determined by the Membership Committee.
      </CPara>
      <CPara>
        Disciplinary action is at the discretion of the Memberhsip
        Committee. As a guide, a slight breach of the Code of Conduct may result in an
        official warning to the member(s) in question. Significant breaches (e.g. acting
        contrary to the Code of Conduct), continual breaches of the Code of Conduct, or
        bringing the WCCA into disripute may result in denial of renewal at the end of
        the current member's Membership Period, or termination of the membership.
      </CPara>

    </div>
  )
}

class CodeOfConduct extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Code of Conduct"
        />
        <div className="container mx-auto max-w-5xl">
          <h1 className="my-4 text-2xl md:text-3xl font-black leading-tight">
            Code of Conduct
          </h1>
          <p className="leading-normal text-gray-800 text-base md:text-xl mb-8">
            As members of the WCCA, our code of conduct governs what we do and how we behave.
          </p>
        </div>

        <section className="bg-gray-100 border-b py-8">
          <div className="container mx-auto max-w-5xl flex flex-wrap pb-12">
            <div className="w-full p-4 flex flex-col flex-grow flex-shrink">
              <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
                <div className="flex flex-wrap justify-center">
                  <CoCText />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default CodeOfConduct

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`